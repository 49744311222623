<template>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <router-link
        class="col-6"
        v-if="hasElectricRights"
        to="/dashboard/energy/consumption"
      >
        <button class="w-100 btn btn-white text-dark border-gray btn-xlg mr-10">
          <span>{{ $t("MENU.DASHBOARD_CONSUMPTION") }}</span>
        </button>
      </router-link>
    </div>

    <div class="row mt-10 d-flex justify-content-center">
      <router-link
        class="col-6"
        v-if="hasSolarRights"
        to="/dashboard/energy/production"
      >
        <button class="w-100 btn btn-white text-dark border-gray btn-xlg">
          <span>{{ $t("MENU.DASHBOARD_PRODUCTION") }}</span>
        </button>
      </router-link>
    </div>

    <div class="row mt-10 d-flex justify-content-center">
      <router-link class="col-6" v-if="hasGasRights" to="/dashboard/gas">
        <button class="w-100 btn btn-white text-dark border-gray btn-xlg">
          <span>{{ $t("MENU.DASHBOARD_GAS") }}</span>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalDashboard",
  data() {
    return {
      hasElectricRights: false,
      hasSolarRights: false,
      hasGasRights: false,
    };
  },
  mounted() {
    let rights = localStorage.getItem("permissions");
    this.hasElectricRights = rights.indexOf("electric") != -1;
    this.hasSolarRights = rights.indexOf("solar") != -1;
    this.hasGasRights = rights.indexOf("gas") != -1;
  },
};
</script>

<style scoped>
.btn-xlg {
  padding: 2rem;
  font-size: 3rem;
}

.border-gray {
  border: solid 1px #a4acb3;
}
</style>
